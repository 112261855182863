<template>
  <div class="faceRecognition">
    <img src="../../assets/product/faceRecognition/1.png" class="headerimg" />
    <div class="serve">
      <div class="title">人脸识别简介</div>
      <div class="serveinfo">
        邦伲德·人脸识别（Face
        Recognition）提供包括人脸检测与分析、五官定位、人脸搜索、人脸比对、人脸验证、人员查重、活体检测等多种功能，为开发者和企业提供高性能高可用的人脸识别服务。
        可应用于智慧零售、在线娱乐、智慧楼宇、在线身份认证等多种应用场景，充分满足各行业客户的人脸属性识别及用户身份确认等需求。
      </div>
    </div>

    <div class="function">
      <div class="title">功能演示</div>
      <img src="../../assets/product/faceRecognition/2.png" />
    </div>

    <div class="characteristic">
      <div class="title">邦伲德人脸识别的特性</div>
      <div class="characteristiclist">
        <div class="characteristicinfo">
          <div class="characteristicheard">
            <img src="../../assets/product/faceRecognition/3.png" />
            <div class="characteristictext">精准识别</div>
          </div>
          <div class="text">
            基于第三代邦伲德祖母模型，融合度量学习、迁移学习、多任务学习等多种训练手段来优化模型；针对不同场景业务的特点，定制化
            fine-tuning 或 distilling 模型，满足业务性能与时延的双重需求。
          </div>
        </div>
        <div class="characteristicinfo">
          <div class="characteristicheard">
            <!-- <img src="../../assets/product/faceRecognition/4.png" />
					<div class="characteristictext">算法领先</div> -->
          </div>
          <div class="text">
            <!-- 邦伲德人脸识别服务在多个国际公开竞赛中刷新纪录，人脸比对在2017年的 LFW 测评准确度高达99.80%，人脸搜索在 MegaFace 竞赛百万规模中首选识别率83.29%排名第一，识别准确率业界领先。 -->
          </div>
        </div>
        <div class="characteristicinfo">
          <div class="characteristicheard">
            <img src="../../assets/product/faceRecognition/5.png" />
            <div class="characteristictext">稳定可靠</div>
          </div>
          <div class="text">
            邦伲德人脸识别服务已经通过邦伲德内部产品海量用户和复杂场景验证，运行稳定、鲁棒性强，服务可用性超过99.9%。
          </div>
        </div>
      </div>

      <div class="characteristiclist">
        <div class="characteristicinfo">
          <div class="characteristicheard">
            <img src="../../assets/product/faceRecognition/6.png" />
            <div class="characteristictext">实时响应</div>
          </div>
          <div class="text">
            人脸识别具备高并发、高吞吐、低时延等特点，即使是规模人脸搜索，仍只需数百毫秒即可处理完毕，满足您的实时使用需求。
          </div>
        </div>
        <div class="characteristicinfo">
          <div class="characteristicheard">
            <img src="../../assets/product/faceRecognition/7.png" />
            <div class="characteristictext">简单易用</div>
          </div>
          <div class="text">
            提供丰富多样的在线 API 和离线识别
            SDK，不仅支持接入云端使用，也支持将离线识别 SDK
            植入应用和硬件设备中，您也可以根据需要结合使用离线识别 SDK 和在线
            API 形成端+云方案，满足不同场景的需求。
          </div>
        </div>
        <div class="characteristicinfo">
          <div class="characteristicheard">
            <img src="../../assets/product/faceRecognition/8.png" />
            <div class="characteristictext">应用广泛</div>
          </div>
          <div class="text">
            人脸识别广泛应用于在线相册、智慧零售、敏感人脸审核、刷脸门禁、人脸考勤、人脸登录、人脸特效、在线考试等众多场景。
          </div>
        </div>
      </div>
    </div>

    <div class="adhibition">
      <div class="title">应用场景</div>
      <div class="adhibitioninfo">
        <div class="adhibitiontitle">商业广告精准投放</div>
        <div class="adhibitiontext">
          人脸分析技术可快速准确的分析画面中出现的人物属性，如年龄、性别等信息，在广告投放方面可以给予一定的指导。
        </div>
      </div>
      <div class="adhibitionimg">
        <img src="../../assets/product/faceRecognition/9.png" />
      </div>
    </div>

    <div class="case">
      <div class="title">客户案例</div>
      <div class="caseimg">
        <img src="../../assets/product/faceRecognition/10.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
.faceRecognition {
  .title {
    text-align: center;
    font-size: 36px;
    font-weight: 500;
    line-height: 54px;
  }

  .serve {
    margin-top: 162px;
    margin-bottom: 140px;
    .serveinfo {
      font-size: 24px;
      font-weight: 500;
      color: #616b80;
      line-height: 44px;
      max-width: 80%;
      margin: auto;
      margin-top: 52px;
    }
  }

  .function {
    text-align: center;
    margin-bottom: 137px;
    img {
      width: 1404px;
      margin-top: 79px;
    }
  }

  .characteristic {
    .characteristiclist {
      display: flex;
      width: 80%;
      margin: auto;
      margin-top: 100px;
      margin-bottom: 140px;
      justify-content: space-between;
      .characteristicinfo {
        width: 400px;
        .characteristicheard {
          text-align: center;
          font-size: 30px;
          font-weight: 500;
          line-height: 45px;
          img {
            width: 100px;
            height: 100px;
          }
          .characteristictext {
            margin-top: 36px;
            font-size: 30px;
            font-weight: 500;
            color: #333333;
            line-height: 45px;
          }
        }
        .text {
          margin-top: 20px;
          font-size: 24px;
          font-weight: 500;
          color: #616b80;
          line-height: 40px;
        }
      }
    }
  }

  .adhibition {
    background-color: #f5f5f9;
    padding-top: 88px;
    padding-bottom: 140px;
    .adhibitioninfo {
      width: 80%;
      margin: auto;
      margin-top: 80px;
      .adhibitiontitle {
        font-size: 30px;
        font-weight: 500;
        color: #333333;
        line-height: 45px;
      }
      .adhibitiontext {
        font-size: 24px;
        font-weight: 400;
        color: #616b80;
        line-height: 48px;
        margin-top: 40px;
        margin-bottom: 140px;
      }
    }
    .adhibitionimg {
      text-align: center;
      img {
        width: 1220px;
      }
    }
  }

  .case {
    margin-top: 140px;
    .caseimg {
      margin-top: 80px;
      text-align: center;
      margin-bottom: 140px;
      img {
        width: 1400px;
      }
    }
  }
}
</style>

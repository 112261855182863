import { render, staticRenderFns } from "./faceRecognition.vue?vue&type=template&id=aa33574e&scoped=true&"
import script from "./faceRecognition.vue?vue&type=script&lang=js&"
export * from "./faceRecognition.vue?vue&type=script&lang=js&"
import style0 from "./faceRecognition.vue?vue&type=style&index=0&id=aa33574e&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa33574e",
  null
  
)

export default component.exports